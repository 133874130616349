<template>
  <!-- ID -->
  <SharedInputError v-if="isUserIdError">
    {{ t("errors.input_user_id") }}
  </SharedInputError>
  <Input
    v-model.trim="moogoldInputValueId"
    :has-error="isUserIdError"
    :placeholder="t('forms.order_user_data.input_id')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- MooGold server -->
  <Select
    v-if="productsStore.moogoldGameServers.length"
    :values="productsStore.moogoldGameServers"
    :not-selected-text="productsStore.moogoldGameServers[0].name"
    :class="$style['form-order-element']"
    :select-values-classes="[$style['form-order-select']]"
    @change="changedServers"
  />
  <!-- General error -->
  <GeneralError v-if="isError">
    {{ t("errors.order_error") }}
  </GeneralError>
  <GeneralError v-if="errorText">
    {{ errorText }}
  </GeneralError>
  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="productsStore.orderMoogold"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import Select from "~/components/Shared/Select/Select.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import GeneralError from "./GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const {
  isUserMailError,
  isUserIdError,
  isError,
  errorText,
  moogoldInputValueId,
  inputValueMail,
  gameServerId,
} = storeToRefs(productsStore);

productsStore.initOrderFormValues();

await productsStore.getMoogoldServersList(
  productsStore.gameData!.id.toString(),
);

const changedServers = async (data: number) => {
  gameServerId.value = data;
};
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
.form-order-select {
  @media screen and (max-width: $mobile) {
    top: auto;
    bottom: 100%;
  }
}
</style>
