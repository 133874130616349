<template>
  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <div :class="$style['form-order-products']">
    <SharedActionCard
      v-for="product in products"
      v-bind:key="product.data.title"
      :class="$style['form-order-product']"
      :img="product.data.picture"
      :action="`${product.amount}x ${product.data.title}`"
      :caption="`${parseFloat(useProductPrice(product.data)) * product.amount} ₽`"
      size="xs"
      :with-arrow="false"
      :discount="
        product.data.discount_percent && `${product.data.discount_percent}%`
      "
    />
  </div>
  <Hint />
  <!-- General error -->
  <GeneralError v-if="isError">
    {{ t("errors.order_error") }}
  </GeneralError>
  <GeneralError v-if="errorText">
    {{ errorText }}
  </GeneralError>
  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="productsStore.orderPubg"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import GeneralError from "./GeneralError.vue";
import Hint from "./PubgForm/Hint.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const { gameData, inputValueMail, isUserMailError, isError, errorText } =
  storeToRefs(productsStore);

const products = computed(() =>
  gameData.value?.products.filter((product) => product.amount > 0),
);
productsStore.initOrderFormValues();
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
.form-order-products {
  margin-bottom: var(--spacing-lg);
}
.form-order-product {
  margin-bottom: var(--spacing-2xs);
}
</style>
