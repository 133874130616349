<template>
  <!-- ID -->
  <SharedInputError v-if="isUserIdError">
    {{ t("errors.input_uid") }}
  </SharedInputError>
  <Input
    v-model="bulldropInputValueId"
    :placeholder="t('errors.input_user_bulldrop_id')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />
  <!-- General error -->
  <GeneralError v-if="isError">
    {{ t("errors.order_error") }}
  </GeneralError>
  <GeneralError v-if="errorText">
    {{ errorText }}
  </GeneralError>
  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="productsStore.orderBulldrop"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import GeneralError from "./GeneralError.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const { isUserMailError, isUserIdError, inputValueMail, bulldropInputValueId } =
  storeToRefs(productsStore);
const isError = ref(false);
const errorText = ref<string | null>(null);

productsStore.initOrderFormValues();
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
</style>
